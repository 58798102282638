import React from "react"
import { Link } from "gatsby"
// import Image from './image'

// import { Navbar, Nav, Form, FormControl, Button } from "react-bootstrap"

const PageItem = ({ image, children, link }) => {
    //   console.log("pageInfo",pageInfo)
    //   console.log("image",image)
    return (
        <div className="pageItem">
            {children}
            {/* <Link to={link} className="link-item pageItem link-no-style 2020">
                {children}
            </Link> */}

        </div>
    )
}

export default PageItem
