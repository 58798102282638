import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import PageItem from "../components/pageItem"
import SEO from "../components/seo"
import Img from "gatsby-image"

const SecondPage = (props) => (
  <Layout pageInfo={{ pageName: "bleistift" }}>
    <SEO title="bleistift" />
    {/* <h1>Ather</h1> */}
    {/* <p>Welcome to page 2</p> */}
    {/* <Link to="/">Go back to the homepage</Link> */}
    <div id="content" className="pages-placeholder  horizontal-scroll-wrapper" 
    style={{width: `3400vw`, background:"#1F3331"}}
    >
      {/* <div className="pages"> */}

        <PageItem  ><Img fluid={props.data.bleistift01.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.bleistift02.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.bleistift03.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.bleistift04.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.bleistift05.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.bleistift06.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.bleistift07.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.bleistift08.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.bleistift09.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.bleistift10.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.bleistift11.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.bleistift12.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.bleistift13.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.bleistift14.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.bleistift15.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.bleistift16.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.bleistift17.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.bleistift18.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.bleistift19.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.bleistift20.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.bleistift21.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.bleistift22.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.bleistift23.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.bleistift24.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.bleistift25.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.bleistift26.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.bleistift27.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.bleistift28.childImageSharp.fluid} /></PageItem>
        <PageItem  ><Img fluid={props.data.bleistift29.childImageSharp.fluid} /></PageItem>



{/* <div>item 1</div>
  <div>item 2</div>
  <div>item 3</div>
  <div>item 4</div>
  <div>item 5</div>
  <div>item 6</div>
  <div>item 7</div>
  <div>item 8</div> */}










        {/* <div className="page 01">page01</div>
      <div className="page 02">page02</div> */}
      {/* </div> */}
    </div>
  </Layout>
)

export default SecondPage

export const fluidImage = graphql`
fragment fluidImage on File {
  childImageSharp {
    fluid(maxWidth: 1000) {
      ...GatsbyImageSharpFluid
    }
  }
}
`;

export const pageQuery = graphql`
  query {
    bleistift01: file(relativePath: { eq: "bleistift_1.jpg" }) {
      ...fluidImage
    }
    bleistift02: file(relativePath: { eq: "bleistift_2.jpg" }) {
      ...fluidImage
    }
    bleistift03: file(relativePath: { eq: "bleistift_3.jpg" }) {
      ...fluidImage
    }
    bleistift04: file(relativePath: { eq: "bleistift_4.jpg" }) {
      ...fluidImage
    }
    bleistift05: file(relativePath: { eq: "bleistift_5.jpg" }) {
      ...fluidImage
    }
    bleistift06: file(relativePath: { eq: "bleistift_6.jpg" }) {
      ...fluidImage
    }
    bleistift07: file(relativePath: { eq: "bleistift_7.jpg" }) {
      ...fluidImage
    }
    bleistift08: file(relativePath: { eq: "bleistift_8.jpg" }) {
      ...fluidImage
    }
    bleistift09: file(relativePath: { eq: "bleistift_9.jpg" }) {
      ...fluidImage
    }
    bleistift10: file(relativePath: { eq: "bleistift_10.jpg" }) {
      ...fluidImage
    }
    bleistift11: file(relativePath: { eq: "bleistift_11.jpg" }) {
      ...fluidImage
    }
    bleistift12: file(relativePath: { eq: "bleistift_12.jpg" }) {
      ...fluidImage
    }
    bleistift13: file(relativePath: { eq: "bleistift_13.jpg" }) {
      ...fluidImage
    }
    bleistift14: file(relativePath: { eq: "bleistift_14.jpg" }) {
      ...fluidImage
    }
    bleistift15: file(relativePath: { eq: "bleistift_15.jpg" }) {
      ...fluidImage
    }
    bleistift16: file(relativePath: { eq: "bleistift_16.jpg" }) {
      ...fluidImage
    }
    bleistift17: file(relativePath: { eq: "bleistift_17.jpg" }) {
      ...fluidImage
    }
    bleistift18: file(relativePath: { eq: "bleistift_18.jpg" }) {
      ...fluidImage
    }
    bleistift19: file(relativePath: { eq: "bleistift_19.jpg" }) {
      ...fluidImage
    }
    bleistift20: file(relativePath: { eq: "bleistift_20.jpg" }) {
      ...fluidImage
    }
    bleistift21: file(relativePath: { eq: "bleistift_21.jpg" }) {
      ...fluidImage
    }
    bleistift22: file(relativePath: { eq: "bleistift_22.jpg" }) {
      ...fluidImage
    }
    bleistift23: file(relativePath: { eq: "bleistift_23.jpg" }) {
      ...fluidImage
    }
    bleistift24: file(relativePath: { eq: "bleistift_24.jpg" }) {
      ...fluidImage
    }
    bleistift25: file(relativePath: { eq: "bleistift_25.jpg" }) {
      ...fluidImage
    }
    bleistift26: file(relativePath: { eq: "bleistift_26.jpg" }) {
      ...fluidImage
    }
    bleistift27: file(relativePath: { eq: "bleistift_27.jpg" }) {
      ...fluidImage
    }
    bleistift28: file(relativePath: { eq: "bleistift_28.jpg" }) {
      ...fluidImage
    }
    bleistift29: file(relativePath: { eq: "bleistift_29.jpg" }) {
      ...fluidImage
    }
  }
`